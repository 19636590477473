@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'global';
@import 'variables';

body {
}

.container {
  background-color: #ffffff;
}

.navbar .container {
  background-color: transparent;
}

.p-image {
  object-fit: cover;
  width: 90%;
  height: 500px;
}
