@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.ems-date-picker {
    input {
        width: 100%;
        border-radius: calcRem(0px);
        background-color: $backgroundColor;
        border: solid calcRem(1px) $secondaryTextColor !important;
        color: $primaryTextColor;
        padding: calcRem(15px) !important;
        text-transform: uppercase;
        cursor: pointer;
    }

    .label {
        font-family: $primary-font;
        color: $primaryTextColor;
    }

    input:focus {
        outline: none !important;
    }
    .form-check-label {
        font-family: $primary-font;
        font-weight: $regular;
        text-transform: uppercase;
        color: $secondaryTextColor;
    }
}
