@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.ems-modal {
    .modal-dialog {
        width: 100%;
        max-width: calcRem(800px);
    }
    .modal-content {
        background-color: $foregroundColor;
        border-radius: 0;
        padding: calcRem(20px);

    }
    .modal-header {
        border-bottom: 0;
    }
    .modal-title {
        color: $primaryTextColor;
        background-color: $foregroundColor;
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: $regular;
    }
    .modal-time {
        color: $secondaryTextColor;
        font-family: $secondary-font;
        text-transform: uppercase;
        padding-top: calcRem(15px);
    }
    .modal-spots {
        color: $secondaryTextColor;
        font-family: $secondary-font;
        font-size: $fontSizeExtraSmall;
    }
    .modal-price {
        color: $secondaryTextColor;
        font-family: $primary-font;
        font-weight: $medium;
        font-size: $fontSizeExtraSmall;
    }
    .modal-description {
        color: $primaryTextColor;
        font-family: $secondary-font;
        font-weight: $regular;
    }
    .modal-footer {
        border-top: 0;
        justify-content: flex-start !important;
        .btn.btn-secondary {
            background-color: $primaryActionColor;
            border-radius: 0;
            border: none;
            padding-left: calcRem(25px);
            padding-right: calcRem(25px);
            font-family: $primary-font;
            font-size: $fontSizeSmall;
            text-transform: uppercase;
        }
    }
    .btn-close {
        display: flex;
        align-self: flex-start;
        background-color: $primaryTextColor !important;
        border-radius: 50%;
    }

    .people-counter__count {
        background-color: $primaryTextColor;
        max-width: calcRem(70px);
        max-height: calcRem(30px);
    }

    .activity-image {
        min-height: calcRem(150px);
        min-width: calcRem(170px);

        max-height: calcRem(250px);
        max-width: calcRem(380px);
    }
}
