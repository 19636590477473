@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.tile {
    padding: calcRem(20px);
    width: 100%;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: calcRem(10px);

    &:hover {
        opacity: 0.8;
    }
    &__title h4 {
        color: $primaryTextColor;
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: $regular;
        text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.20);

    }
    &__time {
        color: $secondaryTextColor;
        text-transform: uppercase;
        p {

        }
    }
    &__teacher {
        color: $secondaryTextColor;
    }
    &__cta {
        color: $primaryTextColor;
        font-family: $primary-font;
        display: flex;
        &__text {
            margin-right: calcRem(10px);
        }
        &__icon img {

        }
    }
}
