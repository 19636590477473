@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.day-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $secondaryTextColor;
  &__top {
    text-transform: uppercase;
    font-family: $primary-font;
  }
  &__bottom {
    font-family: $secondary-font;
    font-size: $fontSizeExtraSmallSmXs;
  }
}
