@import "../../styles/variables";
@import "../../styles/functions";
@import "~bootstrap/scss/bootstrap";

.footer {
  width: 100%;
  color: $primaryTextColor;
  background-color: $foregroundColor;
  padding: calcRem(20px);
  position: sticky;
  font-size: 16px;
  font-family: $primary-font;
  display: flex;
  flex-direction: column;
}

.footer_left {
  float: left;
}

.footer_right {
  float: right;
  display: flex;
  flex-direction: row;
}

.terms {
  margin-left: calcRem(50px) !important;
}