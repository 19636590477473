@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.profile-container {
  background-color: $foregroundColor;
  color: $primaryTextColor;
  padding: calcRem(20px);
  box-shadow: rgba(0, 0, 0, 0.20) 0 3px 8px;

}