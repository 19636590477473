@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.checkbox {

    input.form-check-input {
        border-radius: calcRem(0px);
        background-color: $primaryActionColor;
        color: $primaryTextColor;
        cursor: pointer;
    }
    input.form-check-input:checked {
        outline: none !important;
        background-color: $primaryActionColor !important;
    }
    .form-check-label {
        font-family: $primary-font;
        font-weight: $regular;
        text-transform: uppercase;
        color: $secondaryTextColor;
    }

}
