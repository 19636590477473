//------------ CONFIGURATION -----------------//
:root {
  //Fallback values - identical with EME's configuration
  --backgroundColor: #100E13;
  --foregroundColor: #39364D;
  --primaryActionColor: #EC1D3E;
  --secondaryActionColor: #ed4a65;
  --successColor: #388924;
  --warningColor: #FFB824;
  --dropdownColor: #D2D1D6;
  --primaryTextColor: #ffffff;
  --secondaryTextColor: #d4e2e1;
  $actionColor: linear-gradient(266deg, #EC1D3E, #ed4a65);
}

// Client Colors (Enfield)
$backgroundColor: var(--backgroundColor);
$foregroundColor: var(--foregroundColor);
$primaryActionColor: var(--primaryActionColor);
$secondaryActionColor: var(--secondaryActionColor);
$successColor: var(--successColor);
$warningColor: var(--warningColor);
$dropdownColor: var(--dropdownColor);
$primaryTextColor: var(--primaryTextColor);
$secondaryTextColor: var(--secondaryTextColor);
$actionColor: linear-gradient(266deg, $secondaryActionColor 0%, $primaryActionColor 100%);


$primary-font: 'Oswald', sans-serif;
$secondary-font: 'Roboto', sans-serif;

$thin: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$fontSizeExtraExtraSmall: 7px;
$fontSizeExtraSmallSmXs: 10px;
$fontSizeExtraSmallSm: 12px;
$fontSizeExtraSmall: 14px;
$fontSizeSmall: 18px;
$fontSizeMedium: 22px;
$fontSizeSemiLarge: 26px;
$fontSizeLarge: 30px;
$fontSizeExtraLarge: 34px;
