@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.ems-input-color {
  :disabled {
    opacity: 0.4;
  }
  .form-control-color {
    width: 100%;
    border-radius: calcRem(0px);
    background-color: $backgroundColor;
    border: solid calcRem(1px) $secondaryTextColor !important;
    padding: calcRem(15px) !important;
    font-family: $secondary-font;
    color: $primaryTextColor !important;
    text-transform: none;

    &.error {
      border: thin solid red!important;
    }

    &:focus {
      background-color: $backgroundColor;
    }
  }
  .form-label-color {
    font-family: $primary-font;
    color: $primaryTextColor;
    &:hover {
      cursor: pointer;
    }
  }

  .error-lbl-color {
    color: red;
  }
}

.form-control-color {
  color: $primaryTextColor;
}

::placeholder {
  color: $primaryTextColor;
}

.input.form-control-color.grey.false {
  color: $primaryTextColor !important;
}

