@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";
.phone-container{
  width: 100% !important;

}
.phone-input{
  width: 100% !important;

  border-radius: calcRem(0px);
  background-color: $backgroundColor !important;
  border: solid calcRem(1px) $secondaryTextColor !important;
  font-family: $secondary-font;
  color: $primaryTextColor !important;
  text-transform: none;
  height:55px !important;
}
.phone-search{padding: calcRem(55px) !important;

}
.ems-input {

  :disabled {
    opacity: 0.4;
  }
  .form-control {

    width: 100%;
    border-radius: calcRem(0px);
    background-color: $backgroundColor !important;
    border: solid calcRem(1px) $secondaryTextColor !important;
    padding: calcRem(15px) !important;
    font-family: $secondary-font;
    color: $primaryTextColor !important;
    text-transform: none;

    &.error {
      border: thin solid red!important;
    }

    &:focus {
      background-color: $backgroundColor;
    }
  }
  .form-label {
    font-family: $primary-font;
    color: $primaryTextColor;
    &:hover {
      cursor: pointer;
    }
  }

  .error-lbl {
    color: red;
  }
}

.form-control {
  color: $primaryTextColor;
}

::placeholder {
  color: $primaryTextColor;
}

.input.form-control.grey.false {
  color: $primaryTextColor !important;
}

