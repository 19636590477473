@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";
@import "~bootstrap/scss/bootstrap";

.people-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calcRem(80px);

  &__img {
    margin-bottom: calcRem(5px);
  }
  &__count {
    background-color: $dropdownColor;
    width: calcRem(80px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    @include media-breakpoint-down(md) {
      padding: calcRem(5px);
    }
    span {
      color: $backgroundColor;
    }
    &__control {
      padding: calcRem(2px) calcRem(10px);
      &:hover {
        cursor: pointer;
      }
    }
  }
}
