@import "../../../styles/global";
@import "../../../styles/variables";
@import "../../../styles/functions";

.checkbox__row{
  display: flex !important;
}

.checkbox__row > div {
  margin-right: calcRem(40px);
}








