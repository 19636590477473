@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.search_modal{

}

.search_modal_subtext{
  opacity: .74;
}