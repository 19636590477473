@import "variables";
@import "./functions";

a {
    text-decoration: none !important;

}

h4 {
    color: $primaryTextColor;
}

.pr-20 {
    padding-right: calcRem(20px);
}

p, h1, h2, h3, h4, h5, h6 {
    padding: 0 !important;
    margin: 0 !important;
}

.title-white-primary {
    color: $secondaryTextColor;
    font-family: $primary-font;
    text-transform: uppercase;
}

.white-oswald {
    color: $primaryTextColor;
    font-family: $primary-font;
}

.offwhite-roboto {
    color: $secondaryTextColor;
    font-family: $secondary-font;
}
