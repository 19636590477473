@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.e-button {
    width: 100%;
    color: $primaryTextColor;
    background: $actionColor;
    //background-color: $primaryActionColor;
    padding: calcRem(10px);
    font-family: $primary-font;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.3);
}

.opaque {
    opacity: 0.5;
}
