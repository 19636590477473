@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";
@import "~bootstrap/scss/bootstrap";

.calendar {
    .container-fluid {
        position: relative;
    }
    &__header {
        background-color: $foregroundColor;
        padding: calcRem(10px);
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .col {

    }
    .first {
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .tuesday,
    .wednesday,
    .thursday,
    .friday,
    .saturday,
    .sunday {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &__time {
        font-family: $primary-font;
        color: $secondaryTextColor;
    }
    .col.calendar__time {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &__arrow-left img {
        position: absolute;
        left: calcRem(8px);
        top: calcRem(23px);
    }
    &__arrow-right img {
        position: absolute;
        right: calcRem(8px);
        top: calcRem(23px);
    }
}
