@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.app {
    min-height: 100vh;
    background-color: $backgroundColor;
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $backgroundColor;
    color: $primaryTextColor;
    padding-top: calcRem(0px);

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .spinner-grow{
        color:$primaryActionColor;
        animation-duration: 1.8s;
        width: 3.3rem;
        height: 3.3rem;
        margin-bottom: 1rem;
    }
}