@import "./../../styles/global";
@import './../../styles/variables';
@import './../../styles/functions';

.data__table {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  background-color: $foregroundColor;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  table{
    color: $foregroundColor;
    line-height: calcRem(35px);
    width: 100%;
    thead {
        font-family: $primary-font;
    }
    tbody {
        font-family: $secondary-font;
    }

  }

  h4{
    color: red;
  }

  th {
    overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
    //max-width: calcRem(70px);
    background-color: $foregroundColor;
    background-image: linear-gradient(rgb(0 0 0/60%) 0 0);
    color: $primaryTextColor;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.30);
  }

  td {
    font-size: $fontSizeExtraSmall;
    background-color: $foregroundColor !important;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  }

  &__actions {
    display: flex;
    flex-direction: row;

    &__action {
        padding-right: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__top .row {
    font-family: $primary-font;
    color: $primaryTextColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.table-icons {
  display: flex;
  justify-content: space-around;
}

.page-item.disabled .page-link {
  color: $primaryTextColor !important;
  pointer-events: none;
  background-color: transparent !important;
  border-color: transparent !important;
}


.page-item.active .page-link {
  z-index: 3;
  color: #826d5e !important;
  background-color: transparent !important;
  border-right-color: #826d5e !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  font-weight: bold !important;
}


.page-link {
  position: relative;
  display: block;
  color: #c4aa98 !important;
  text-decoration: none;
  background-color: transparent !important;
  border-right-color: #826d5e !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link.next {
  border-color: transparent !important;
  color: #826d5e !important;
}


.table {
  color: $primaryTextColor !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  width: 300px;
}

.table__footer {
    color: $primaryTextColor;
    font-family: $secondary-font;
    font-size: $fontSizeExtraSmall;
}

.page-item.page-link {
    color: $primaryTextColor !important;
}



.table > :not(caption) > * > * {
    background-color: $foregroundColor !important;
}


//TODO fix this
.table-striped>tbody>tr:nth-of-type(even) > * {
  border: none;
  background-image: linear-gradient(rgb(0 0 0/12%) 0 0);
  background-color: $foregroundColor;
  color: $primaryTextColor !important;
}

.table-striped>tbody>tr:nth-of-type(odd) > * {
  color: $primaryTextColor !important;
}



.table-dark{
    background-color: $backgroundColor !important;
    --bs-table-striped-bg: $foregroundColor !important;
}

.table > :not(:first-child) {
    border-top: 0px !important;
}

.page-link.next {
    color: $primaryTextColor !important;
    font-family: $secondary-font;
    font-size: $fontSizeExtraSmall;
}

.page-item.active .page-link {
    color: $primaryTextColor !important;
    font-family: $secondary-font;
    font-size: $fontSizeExtraSmall;
    border-right: solid 1px $foregroundColor !important;
}

.form-control {
    border-radius: 0 !important;
}
