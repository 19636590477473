@import "./../../styles/global";
@import "./../../styles/variables";
@import "~bootstrap/scss/bootstrap";
@import "./../../styles/functions";

.cart {
    width: 100%;
    height: calcRem(200px);
    padding: calcRem(20px);
    @include media-breakpoint-down(md) {
        padding: calcRem(10px);
    }
    display: flex;
    flex-direction: row;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    align-items: flex-start;
    justify-content: space-between;
    background-color: $foregroundColor;
    &__left {
        // width:50%;
        justify-content:space-between;
        display: inline-flex;
        h4 {
            text-transform: uppercase;
            font-weight: $regular;
        }
        &__date {
            font-size: $fontSizeSmall;
            @include media-breakpoint-down(md) {
                font-size: $fontSizeExtraSmall;
            }
        }
        &__time {
            font-size: $fontSizeSmall;
            @include media-breakpoint-down(md) {
                font-size: $fontSizeExtraSmall;
            }
        }
        &__teacher {
            font-size: $fontSizeExtraSmall;
            font-weight: $medium;
        }

        &__dropdown{

        }

    }
    &__middle {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        .middle-right {
            padding-top: calcRem(35px);
            padding-left: calcRem(20px);
            @include media-breakpoint-down(md) {
                padding-top: calcRem(10px);
                padding-left: calcRem(0px);
            }
        }
        &__price p {
            color: $primaryTextColor;
            font-family: $secondary-font;
            font-weight: $bold;
            font-size: $fontSizeSmall;
            text-align: left;
            @include media-breakpoint-down(md) {
                font-size: $fontSizeExtraSmall;
            }
        }

    }
    &__right img {
        width: calcRem(20px);
        height: calcRem(20px);
    }

    &__dropdown {
        background-color: $foregroundColor;
        padding:calcRem(20px);
        padding-top:calcRem(0px);
    }

    &__customer{
        background-color: $foregroundColor !important;
        padding: calcRem(20px) !important;

    }
}
