@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";
.container{
  display: flex;
  gap: 10px;
  align-content:center;
  background-color: $backgroundColor;
}
.pill-team{
  width: auto;
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: $backgroundColor !important;
  border: 5px;
  margin: 0 auto;
  color: white;
  text-transform: uppercase;
  font-size:13px;
  font-family: $primary-font;
  font-weight: $regular;
  outline: none !important;
  border-radius: 0 !important;
}
.pill{
  width: auto;
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: $backgroundColor !important;
  border: none;
  margin: 0 auto;
  color: white;
  text-transform: uppercase;
  font-size:13px;
  font-family: $primary-font;
  font-weight: $regular;
  outline: none !important;
  border-radius: 0 !important;
}
.pill-close{
  cursor: pointer;
  width: 12px !important;
  height: 12px !important;
  margin-left: 5px;
  margin-right: 10px;
  align-items:center;
  justify-content: space-between;
}

.dropdown-currencies {



  .form-label {
    font-family: $primary-font;
    color: $primaryTextColor;
    &:hover {
      cursor: pointer;
    }
  }
  .error-lbl {
    color: red;
  }

  &-toggle.btn.btn-success {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondaryTextColor !important;
    border: none;
    padding: calcRem(10px);
    color: $primaryActionColor;
    text-transform: uppercase;
    font-family: $primary-font;
    font-weight: $regular;
    outline: none !important;
    border-radius: 0 !important;
  }



  &-toggle.btn.btn-success:focus-within{
    border: none !important;
    outline: none !important;
    background-color: $secondaryTextColor !important;
    color: $primaryActionColor;
  }

  &-toggle.btn.btn-success:hover{
    border: none !important;
    outline: none !important;
    background-color: $primaryActionColor !important;
    color: $secondaryTextColor;
  }


  .dropdown-menu-currencies.show {
    width: 100%;
    max-height: 400px;
    overflow:auto;
    background-color: $secondaryTextColor;
    border-radius: 0 !important;
    color: $backgroundColor !important;

  }
  .dropdown-menu-currencies.show:hover {
    background-color: $backgroundColor;
  }
  .dropdown-item-currencies {
    color: $primaryActionColor !important;
    font-family: $secondary-font;
    font-weight: $medium;
    display:flex;
    input {width:50%;
    }
    button{
      padding:5px;
      margin-left: 5px;
    }
  }

  .dropdown-item-currencies:hover  {
    color: $primaryTextColor !important;
    background-color: $backgroundColor !important;
  }
  .search-item{
    width: 100%;
    border-radius: calcRem(0px);
    background-color: $backgroundColor;
    border: solid calcRem(1px) $secondaryTextColor !important;
    padding: calcRem(5px) !important;
    font-family: $secondary-font;
    color: $primaryActionColor !important;
    text-transform: none;


  }
  ::placeholder {
    color: $primaryActionColor !important;
  }

  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success,
  .btn-success:active,
  .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    border-color: transparent !important;
  }
  .btn-success:focus {
    box-shadow: 0 0 0 0;
  }
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
  }
}

.success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 !important;
}


