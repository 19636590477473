@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.box {
  background-color: $foregroundColor;
  color: $primaryTextColor;
  padding-top: calcRem(0px);
  box-shadow: rgba(0, 0, 0, 0.20) 0 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;

}


.credit_card_container{
  margin-bottom: calcRem(15px);
  display: block;
  width: 100%;
}

.credit_card_input{
  width: 100%;
  border-radius: calcRem(0px);
  background-color: $backgroundColor !important;
  filter: brightness(115%);
  border: solid calcRem(0.2px) $secondaryTextColor !important;
  flex-direction:column;
  font-family: $secondary-font;
  color: $primaryTextColor !important;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  position: center;
}

.credit_card_field{
  width: 100%;
  border-radius: calcRem(0px);
  background-color: $backgroundColor !important;
  border: solid calcRem(0px) $secondaryTextColor !important;
  display:flex;
  padding: calcRem(15px) !important;
  font-family: $secondary-font;
  color: $primaryTextColor !important;
  text-transform: none;
}

.checkout_emoji{
  font-size: 2rem;
  filter: grayscale(100%) sepia(100%) hue-rotate(90deg);
}

.checkout_text{
  text-transform: none;
}

.card_summary{
  background-color: $successColor !important;
  color: $secondaryTextColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: calcRem(15px);
  padding-left: calcRem(20px) !important;
  padding-right: calcRem(20px) !important;
}

.card_header{
  font-weight: bold;
}

.card_body{
  color: $secondaryTextColor;
}

.card_right{
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout_container{
  display:flex;
  justify-content: space-between;
}

.final {
  max-width: calcRem(1273px);
}