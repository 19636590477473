@import "./../../styles/global";
@import "./../../styles/variables";
@import "./../../styles/functions";

.dropdown {
    .form-label {
        font-family: $primary-font;
        color: $primaryTextColor;
        &:hover {
            cursor: pointer;
        }
    }
    .error-lbl {
        color: red;
    }

    &-toggle.btn.btn-success {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $dropdownColor !important;
        border: none;
        padding: calcRem(10px);
        color: $primaryTextColor !important;
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: $regular;
        outline: none !important;
        border-radius: 0 !important;
        text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    }

    &-toggle.btn.btn-success:focus-within{
        border: none !important;
        outline: none !important;
        background-color: $dropdownColor !important;
        color: $secondaryActionColor;
    }

    &-toggle.btn.btn-success:hover{
        border: none !important;
        outline: none !important;
        background-color: $dropdownColor !important;
        -webkit-transition:all 0.2s ease-in-out;
        transition:0.2s ease-in-out;
        filter: brightness(80%);
    }


    .dropdown-menu.show {
        width: 100%;
        background-color: $dropdownColor;
        border-radius: 0 !important;
        color: $primaryTextColor !important;
    }
    .dropdown-menu.show:hover {
        background-color: $dropdownColor;
    }
    a.dropdown-item {
        color: $primaryTextColor !important;
        font-family: $secondary-font;
        font-weight: $medium;
    }
    a.dropdown-item:hover {
        color: $primaryTextColor !important;
        background-color: $primaryActionColor;
    }
    .btn-check:checked + .btn-success,
    .btn-check:active + .btn-success,
    .btn-success:active,
    .btn-success.active,
    .show > .btn-success.dropdown-toggle {
        border-color: transparent !important;
    }
    .btn-success:focus {
        box-shadow: 0 0 0 0;
    }
    .show > .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0;
    }
}

.success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 !important;
}









