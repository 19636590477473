@import "../../styles/variables";
@import "../../styles/functions";
@import "../../styles/global";

.view {
    background-color: $backgroundColor;
    padding: calcRem(20px);
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.view-dark {
    background-color: $backgroundColor;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: $actionColor !important;
    box-shadow: rgba(0, 0, 0, 0.55) 0 3px 8px;
    border-radius: 0px !important;
    min-width:0% !important;
    max-width:100% !important;
    border-left: 5px solid $secondaryTextColor;
    color: $primaryTextColor !important;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.30);
}

.nav {
    max-width:100%;
}

.h4 {
    background-color: red;
}
a.nav-link {
    color: $secondaryTextColor;
    //max-width: 100%;
}

a.nav-link:hover{
    color: $primaryActionColor;
}

.bg-dark-shadow {
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
}



.side-menu {
    background-color: $foregroundColor;
    max-width:100% !important;
    min-width:0% !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 8px;

}